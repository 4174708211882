import { useStaticQuery, graphql, Link } from "gatsby"
import { Container, Row, Col } from "react-bootstrap"
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react"
import "react-input-range/lib/css/index.css"
import useOutsideClick from "../../../hooks/useOutsideClick"
import { handleLogin, isLoggedIn, getUser } from "../../../services/auth"

import Layout from "../../../templates/Page"
import FilterResult from "../../ContentBuilder/FilterResult"
import MainDesignFilter from "../../../templates/Includes/MainDesignFilter"
import StayInTouch from "../../../templates/Includes/StayInTouch"
import SidebarFilter from "../../../templates/Includes/SidebarFilter"

import SearchBar from "../../SearchBar"
import ContactUs from "../../ContactUs"
import Elements from "../../../templates/Elements/Elements"
import CreateBoardModal from "../../ContentBuilder/Modal/CreateBoardModal"
import { connect } from "react-redux"
import { doFilter, filterVariant } from "../../../services/filter"
import QuickViewModalData from "../../ContentBuilder/Modal/QuickViewModalData"
import { setCurrent } from "../../../state/nav"
import { shuffle } from "../../../services/ultility"
import config from "../../../config/config"
import { getBoardFromLocalStorage } from "../../../state/board"
import RecentViews from "../../RecentViews"
import { resetFilter } from "../../../state/filter"

const FinishesFixturesList = ({ activeFilters, sort, currentBoards, dispatch }) => {
  const [items, setItems] = useState([])
  useEffect(() => {
    dispatch(setCurrent('finishes-fixtures'))
    dispatch(getBoardFromLocalStorage())
  }, [])
  const [show, setShow] = useState(false)
  const ref = useRef()
  useOutsideClick(ref, () => {
    setShow(false)
  })

  const resetFilterHandler = useCallback(() => dispatch(resetFilter()), [])

  const filterEl = useMemo(() => <AllFinishesFixtures activeFilters={activeFilters} currentBoards={currentBoards} sort={sort} setItems={data => setItems(data)} resetFilter={resetFilterHandler} />, [activeFilters, currentBoards, sort])
  return (
    <Layout>
      <div className="container">
        <MainDesignFilter />
        <div className="wrap-section no-margin">
          <div className="explore-function-content">
            <FilterResult />
          </div>
        </div>

        <div className="wrap-section explore-wrap row">
          <div className="col-lg-3">
            <SidebarFilter items={items} />
          </div>
          {filterEl}
        </div>
        <RecentViews />
      </div>
      <StayInTouch />
    </Layout>
  )
}

const mapStateToProps = (state) => ({
  activeFilters: state.filter.active,
  currentBoards: state.board.all,
  sort: state.sort.current
})

export default connect(mapStateToProps, null)(FinishesFixturesList)

const AllFinishesFixtures = ({ activeFilters, currentBoards, sort, setItems, resetFilter }) => {
  const loadPage = () => {
    setPageLimit(pageLimit + 12)
    if (pageLimit + 12 >= tiles.length) {
      setHasMore(false)
    }
  }
  const [pageLimit, setPageLimit] = useState(12)
  const [hasMore, setHasMore] = useState(true)

  const allItems = useStaticQuery(graphql`
    {
        silverStripeDataObject(className: {eq: "Undigital__Objects__GlobalConfig"}) {
        UndigitalGlobalConfig {
          hidePricingGlobally
        }
      }
      allSilverStripeDataObject(filter: {className: {eq: "Undigital__Objects__FinishesFixtures"}, UndigitalFinishesFixtures: {status: {eq: "Active"}}}, sort: {fields: UndigitalFinishesFixtures___objectID, order: DESC}) {
        nodes {
          UndigitalFinishesFixtures {
            urlSegment
            name
            showTitleOnTile
            tileBlackTitle
            about
            warranty
            additionalInfo
            objectID
            FeaturedVariation {
              UndigitalFinishesFixturesVariation {
                featureProductImagePath
                imagePosition
                name
                status
                rrp
                colourID
                objectID
              }
            }
            Brand {
              UndigitalBrand {
                name
              }
            }
            Suppliers {
              UndigitalSupplier {
                name
              }
            }
            Variations {
              UndigitalFinishesFixturesVariation {
                name
                status
                rrp
                colourID
                objectID
                featureProductImagePath
              }
            }
            brandID
            categoryID
          }
        }
      }
    }
  `)
  const host = config.host
  const globalConfig = allItems.silverStripeDataObject.UndigitalGlobalConfig
  const [showAddBoard, setShowAddBoard] = useState(false)
  const url = typeof window !== "undefined" ? window.location.pathname : ""
  var checkURL = url.substring(0, url.length - 1)
  const segment = checkURL.substring(checkURL.lastIndexOf("/") + 1)
  const search = typeof window !== "undefined" ? window.location.search : "/"
  const params = new URLSearchParams(search)
  // var sortBy = params.get("sort")
  var keyword = params.get("keyword")
  const unfiltered_items = allItems.allSilverStripeDataObject.nodes
  let _allData = []
  var i = 0
  for (; unfiltered_items[i];) {
    var _item = unfiltered_items[i].UndigitalFinishesFixtures
    var FeaturedVariation = _item.FeaturedVariation;
    _item.variantTiles = _item.Variations ? _item.Variations.map(v => {
      return {
        ...v.UndigitalFinishesFixturesVariation,
        featured: v.UndigitalFinishesFixturesVariation.objectID == FeaturedVariation.UndigitalFinishesFixturesVariation.objectID
      }
    }).filter(v => v.status === 'Active').sort((i1, i2) => {
      if (i1.objectID == FeaturedVariation.UndigitalFinishesFixturesVariation.objectID) return -1;
      if (i2.objectID == FeaturedVariation.UndigitalFinishesFixturesVariation.objectID) return 1;
      return 0;
    }) : [];
    var image_path = null
    if (FeaturedVariation && FeaturedVariation.UndigitalFinishesFixturesVariation.featureProductImagePath) {
      image_path = FeaturedVariation.UndigitalFinishesFixturesVariation.featureProductImagePath
    }
    _item.Image = image_path;
    var rrp = 0;
    if (FeaturedVariation) {
      rrp = FeaturedVariation.UndigitalFinishesFixturesVariation.rrp
      _item.imagePosition = FeaturedVariation.UndigitalFinishesFixturesVariation.imagePosition
    }
    _item.featuredRRPrice = rrp;
    var name = _item.name
    var about = _item.about
    var warranty = _item.warranty
    var suppliers = "", brand = "";
    var suppliersArr = [];
    var suppliersObj = _item.Suppliers
    var brandObj = _item.Brand;
    if (brandObj !== null) {
      brand = brandObj.UndigitalBrand.name;
    }
    var storeysList = _item.Storeys
    if (suppliersObj) {
      suppliersObj.forEach(function (item, index) {
        suppliersArr.push(item.UndigitalSupplier.name)
      })
      suppliers = suppliersArr.join(", ")
    }
    _item.brand = brand;
    _item.suppliers = suppliers;

    var match = true
    if (keyword) {
      keyword = keyword.toLowerCase()
      let _name = name.toLowerCase()
      let _about = about.toLowerCase()
      let _warranty = warranty.toLowerCase()
      let _suppliers = suppliers.toLowerCase()

      let matched_name = _name.search(keyword)
      let matched_about = _about.search(keyword)
      let matched_warranty = _warranty.search(keyword)
      let matched_suppliers = _suppliers.search(keyword)

      if (
        matched_name === -1 &&
        matched_about === -1 &&
        matched_warranty === -1 &&
        matched_suppliers === -1
      ) {
        match = false
      }
    }
    var itemPrice = FeaturedVariation !== null ? FeaturedVariation.UndigitalFinishesFixturesVariation.rrp : 0;
    itemPrice = parseFloat(
      Math.round(itemPrice * 1000) / 1000
    ).toFixed(2)
    _item.formattedPrice =
      "$" + itemPrice.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    _item.rrp = _item.formattedPrice
    _item.link = '/explore/finishes-fixtures/' + _item.urlSegment;
    _item.className = 'FinishesFixtures';
    if (_item.brandID) _item.brands = [_item.brandID]
    if (_item.Variations) {
      _item.colorArray = []
      for (const i of _item.Variations) {
        const variant = i.UndigitalFinishesFixturesVariation
        if (variant.colourID && !_item.colorArray.some(ca => ca == variant.colourID)) _item.colorArray.push(variant.colourID)
      }
    }
    if (_item.categoryID) _item.categoryArray = [_item.categoryID]

    if (match) {
      _allData.push(_item)
    }
    i++
  }

  // deep copy the original tiles
  const _allTiles = JSON.parse(JSON.stringify(_allData))
  useEffect(() => {
    setItems(_allTiles)
  }, [])
  // do filter

  if (activeFilters.length) _allData = doFilter(activeFilters, _allData)

  // const _total_items = _allData.length
  const currentData = _allData
  // if (_total_items == currentData.length) {
  //   hasMore = false
  // }

  const [showQuickView, setShowQuickView] = useState(false);
  const [quickViewData, setQuickViewData] = useState(null);
  const setUpQuickViewModal = useCallback((e, item, vt) => {
    e.preventDefault();
    item.vt = vt
    setQuickViewData(item);
    setShowQuickView(true);
  }, [])

  const [objectClass, setObjectClass] = useState();
  const [objectID, setObjectID] = useState();
  const [variantID, setVariantID] = useState(null);
  const setShowBoard = (className, objectID, vt) => {
    if (isLoggedIn()) {
      setObjectClass(className);
      setObjectID(objectID);
      setShowAddBoard(true);
      if (vt) {
        setVariantID(vt.objectID)
      }
    } else {
      if (typeof window !== 'undefined') {
        window.location.href = "/login/";
      }
    }
  }


  const [tiles, setTiles] = useState([]);

  useEffect(() => {
    let renderTiles = []
    for (const [idx, itemRaw] of currentData.entries()) {
      const item = JSON.parse(JSON.stringify(itemRaw))
      let isSaved = false

      if (item.variantTiles && item.variantTiles.length) {
        for (const [index, vt] of item.variantTiles.entries()) {
          isSaved = false
          for (const board of currentBoards) {
            const items = JSON.parse(board.Items)
            for (const key in items) {
              if (key.includes(item.className) && items[key].ID == item.objectID && items[key].VariantID == vt.objectID) {
                isSaved = true
              }
            }
          }
          if (activeFilters.length && !filterVariant(activeFilters, vt)) continue
          let ip = vt.rrp || 0;
          ip = parseFloat(
            Math.round(ip * 1000) / 1000
          ).toFixed(2)
          let formattedPrice = "$" + ip.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
          let vt_image = null
          if (vt.featureProductImagePath) {
            vt_image = vt.featureProductImagePath
          }
          vt.Image = vt_image
          renderTiles.push({
            id: vt.objectID,
            tilename: vt.name ? vt.name : (item.name + ' ' + vt.name),
            featuredRRPrice: vt.rrp,
            featured: vt.featured,
            item: { ...item },
            vt: { ...vt },
            tileImageSrc: vt.Image,
            formattedPrice,
            isSaved,
          })
        }
      } else {
        for (const board of currentBoards) {
          const items = JSON.parse(board.Items)
          for (const key in items) {
            if (key.includes(item.className) && items[key].ID == item.objectID) {
              isSaved = true
            }
          }
        }
        renderTiles.push({
          id: item.objectID,
          tilename: item.name,
          featuredRRPrice: item.featuredRRPrice,
          featured: false,
          item: { ...item },
          vt: null,
          tileImageSrc: item.Image,
          formattedPrice: item.formattedPrice,
          isSaved,
        })
      }
    }

    renderTiles = shuffle(renderTiles)

    renderTiles.sort((i1, i2) => {
      if (i1.featured && !i2.featured) return -1;
      if (!i1.featured && i2.featured) return 1;
      return 0;
    });

    renderTiles.sort((a, b) => {
      if (sort === "az") {
        return a.tilename > b.tilename ? 1 : -1
      } else if (sort === "latest") {
        return Number(a.objectID) < Number(b.objectID) ? 1 : -1
      } else if (sort === "pricehightolow") {
        return a.featuredRRPrice < b.featuredRRPrice ? 1 : -1
      } else if (sort === "pricelowtohigh") {
        return a.featuredRRPrice > b.featuredRRPrice ? 1 : -1
      } else {
        return Number(a.objectID) < Number(b.objectID) ? 1 : -1
      }
    })

    setTiles(renderTiles)
  }, [activeFilters, currentBoards, sort])

  const renderTileGrid = useMemo(() => {
    if (tiles.length > 0 && tiles.length === tiles.slice(0, pageLimit).length) {
      setHasMore(false)
    } else {
      setHasMore(true)
    }
    return tiles.length > 0 ? tiles.slice(0, pageLimit).map((tile, idx) => (
      <div key={`finishes-${tile.item.urlSegment}${idx}`} className={`grid-item ${tile.isSaved ? 'liked' : ''} ${tile.item.showTitleOnTile == 1 ? ' showtitle' : ''} ${tile.item.tileBlackTitle == 1 ? 'text-black' : ''} ${tile.item.Image ? '' : ' no-image'}`}>
        <button
          type="button"
          className="btn-like"
          onClick={() => setShowBoard(tile.item.className, tile.item.objectID, tile.vt)}
        >
          {tile.isSaved ? (
            <>
              <svg className="clear-heart" xmlns="http://www.w3.org/2000/svg" width="35" height="35" viewBox="0 0 35 35">
                <path id="Path_878" data-name="Path 878" d="M-4.556-1.307c-5.906-10.042-18.85-3.3-16.35,8.076C-18.458,17.9-4.556,23.792-4.556,23.792S9.348,17.9,11.794,6.769C14.295-4.611,1.352-11.349-4.556-1.307Z" transform="translate(21.982 6.975)" fill="#fff" stroke="#fff" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="1.5" />
              </svg>
              <div className="clear-item"></div>
            </>
          ) : <svg id="icon-heart" width="35" height="35" viewBox="0 0 35 35"><path d="M-4.556-1.307c-5.906-10.042-18.85-3.3-16.35,8.076C-18.458,17.9-4.556,23.792-4.556,23.792S9.348,17.9,11.794,6.769C14.295-4.611,1.352-11.349-4.556-1.307Z" transform="translate(21.982 6.975)" fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="1.5"></path></svg>}
        </button>
        <Link onClick={e => setUpQuickViewModal(e, tile.item, tile.vt)} className={`product-variant grid-item-inner ${tile.item.imagePosition}`} to={`/explore/finishes-fixtures/${tile.item.urlSegment}/`}>
          <>
            {tile.tileImageSrc && (
              <img className="p-variant" src={tile.tileImageSrc} alt={tile.tilename} />
            )}
            <div className="grid-caption">
              <h4>{tile.tilename}</h4>
              {globalConfig.hidePricingGlobally == 0 && (
              <div className="grid-bottom">
                <p className="variant-name">{tile.formattedPrice}</p>
              </div>
              )}
            </div>
          </>
        </Link>
      </div>
    )) : (
      <p>Awh dam! Sorry, we can't find what you're looking for. Try a different search or <a href="#" onClick={() => resetFilter()}>reset</a> your filters to continue exploring Birdy.</p>
    )
  }, [tiles, pageLimit])

  return (
    <>
      <div className="col-lg-9">
        <div className="grid">
          {renderTileGrid}
        </div>
        {tiles.length > 0 && hasMore && (
          <div className="text-center">
            <button onClick={loadPage} className="btn btn-black btn-lg mt-5">
              LOAD MORE
            </button>
          </div>
        )}
      </div>
      <CreateBoardModal
        show={showAddBoard}
        handleClose={() => setShowAddBoard(false)}
        objectClass={objectClass}
        objectID={objectID}
        variantID={variantID}
      />
      {quickViewData !== null && (
        <QuickViewModalData
          show={showQuickView}
          handleClose={() => setShowQuickView(false)}
          item={quickViewData}
          hidePricingGlobally={globalConfig.hidePricingGlobally}
        />
      )}
    </>
  )
}
